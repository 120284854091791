import React, { useEffect, useLayoutEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
//import Layout from "../layout/RecruitementLayout";
import { userslist } from "../../slice/userList/userSlice"
const PrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { isAuthenticate } = useSelector(state => state.auth)
    // const companyId = process.env.REACT_APP_DEFAULT_COMPANY_ID;

    useEffect(() => {
        async function fetchUserList() {
            try {
                await dispatch(userslist({}))
            } catch (err) {
                console.log(err)
            }
        }
        fetchUserList()
    }, [])
    return (
        <Route {...rest} render={props => {
            if (isAuthenticate)
                //return <Layout><Component {...props} /></Layout>
                return <Component {...props} />
            return window.location.assign(process.env.REACT_APP_REDIRECT_LOGOUT_URL);
            // return <Redirect
            //     to={{
            //         pathname: "/"
            //         , state: { from: props.location }
            //     }}
            // />
        }} />
    )
};
export default PrivateRoute;