import React, { useState } from 'react';
import AdminHeader from "../../../components/elements/header/Header";
import AdminNavbar from "../../../components/elements/navbar/Navbar";
import AccountSubmenu from '../../pages/submenu/AccountSubmenu';
import './adminaccounts.scss';

const AccountExpenses = () => {
    return (
        <>
            <AdminHeader />
            <AdminNavbar />
            <AccountSubmenu />
        </>
    )
}

export default AccountExpenses;

