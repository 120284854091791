import React, { useState, useCallback, useRef, useEffect } from 'react'
import { updateDetails, clearState } from "../../../../slice/admin/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError } from "../../../../components/utils/messages/messageSlice";
import { updateHeaderDetails } from "../../../../slice/auth/authSlice";
import SubmitButton from "../../../../components/ui/submitButton";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AWS from 'aws-sdk'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-bootstrap/Modal'

const UpdateUserDetails = () => {
    const dispatch = useDispatch();
    const { userDetails, isUpdate, msg, isError } = useSelector(state => state.alluserList);
    const { userInfo } = useSelector(state => state.auth);
    const [userId, setUserId] = useState(userDetails.id);
    const [userName, setUserName] = useState(userDetails.firstName + ' ' + userDetails.lastName);
    const [role, setRole] = useState(userDetails.role);
    const [currentCountry, setCurrentCountry] = useState(userDetails.country);
    const [currentCity, setCurrentCity] = useState(userDetails.city);
    const [nationality, setNationality] = useState(userDetails.nationality);
    const [birthDate, setBirthDate] = useState(userDetails.birthDate);
    const [email, setEmail] = useState(userDetails.email);
    const [backupEmail, setBackupEmail] = useState(userDetails.backupEmail);
    const [mobileNo, setMobileNo] = useState(userDetails.mobileNo);
    const [landlineNo, setLandlineNo] = useState(userDetails.landlineNo);
    const [skypeId, setSkypeId] = useState(userDetails.skypeId);
    const [whatsApp, setWhatsapp] = useState(userDetails.whatsApp);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const [file, setFile] = useState(userDetails.profileImage);
    const [userImg, setUserImg] = useState(userDetails.profileImage);
    const defaultSrc = process.env.REACT_APP_IMG_CLOUD_BASE_URL + "users/images/user.png";
    //image crop start
    const [isImageUpdate, setIsImageUpdate] = useState(false)
    const [newImageData, setNewImageData] = useState('')
    const [profileImage, setProfileImage] = useState(userInfo.profileImage)

    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState(
        {
            unit: 'px',
            width: 150,
            height: 150,
            aspect: 1 / 1
        }
    );
    const [completedCrop, setCompletedCrop] = useState(null);
    const [cropModal, setCropModal] = useState(false)

    useEffect(() => {
        if (userDetails.profileImage != 'users/images/user.png') {
            setFile(userDetails.profileImage)
        }
    }, [])

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setCropModal(true)
        }
    }
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }
        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        const base64Image = canvas.toDataURL("image/jpeg");
        setIsImageUpdate(true)
        setNewImageData(base64Image)
    }, [completedCrop]);
    const handleClose = () => {
        setCropModal(false);
    }
    const handleCropDone = () => {
        setCropModal(false);
    }
    const cancelCrop = () => {
        setCropModal(false);
        setNewImageData('')
        setIsImageUpdate(false)
        setCompletedCrop(null)
    }
    //image crop end

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const submitUserDetails = (e) => {
        e.preventDefault()
        setSuccess(false);
        // setLoading(true);
        const firstName = userName.split(" ")[0];
        const lName = userName.split(" ")[1] + " " + userName.split(" ")[2];
        const country = currentCountry;
        const city = currentCity;

        if (!firstName || !role || !email) {
            dispatch(showError({ msg: 'All fields required' }))
            setLoading(false);
        } else {
            setLoading(true);
            if (password != "" || confirmPassword != "") {
                if (password != confirmPassword) {
                    dispatch(showError({ msg: 'Password and confirm password not matched' }))
                    setLoading(false);
                } else {
                    const imgFile = userImg
                    dispatch(updateDetails({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, imgFile }))
                }
            } else {
                if (isImageUpdate) {
                    let s3bucket = new AWS.S3({
                        accessKeyId: process.env.REACT_APP_IAM_USER_KEY,
                        secretAccessKey: process.env.REACT_APP_IAM_USER_SECRET,
                        Bucket: process.env.REACT_APP_BUCKET_NAME
                    });
                    let buf = new Buffer(newImageData.replace(/^data:image\/\w+;base64,/, ""), 'base64')
                    s3bucket.createBucket(function () {
                        var params = {
                            Bucket: process.env.REACT_APP_BUCKET_NAME + '/users/images',
                            Key: Date.now() + '.png',
                            ContentEncoding: 'base64',
                            ContentType: 'image/png',
                            Body: buf
                        };
                        s3bucket.upload(params, function (err, data) {
                            if (err) {
                                console.log(err)
                            } else {

                                setProfileImage(data.key)
                                dispatch(updateDetails({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, imgFile: '/' + data.key }))
                            }
                        });
                    })

                } else {
                    const imgFile = userImg
                    dispatch(updateDetails({ file, userId, firstName, lName, country, city, nationality, birthDate, backupEmail, mobileNo, landlineNo, skypeId, whatsApp, password, confirmPassword, imgFile }))

                }
            }
        }
    }


    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: msg }))
            setLoading(false);
            setSuccess(false);
        }
        if (isUpdate) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            if (userInfo.id === userDetails.id) {

                dispatch(updateHeaderDetails({ name: userDetails.firstName + " " + userDetails.lastName, userImg: userDetails.userImg }))
            }
            setLoading(false);
            setSuccess(false);
        }
    }, [isError, isUpdate])
    const getFiles = (e) => {
        setFile(e.base64)
    }


    return (
        <>
            {
                (cropModal) ?
                    <Modal className="dk-uploadImgModal" centered show={true} onHide={handleClose}>
                        <Modal.Body>
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-md-12">
                                <div class="d-flex justify-content-center btn-container">
                                    <button class="dk-cancelBtn" onClick={cancelCrop}>Cancel</button>
                                    <SubmitButton txt="Use This" loading={loading} success={success} onClick={handleCropDone} position="" className="dk-updateBtn" />
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal> : null
            }

            <div className="dk-userEditMain">
                <div className="admin-edit-profile">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center profile-photo">
                            <figure id="edit_image">
                                {
                                    (completedCrop) ?
                                        <canvas
                                            ref={previewCanvasRef}
                                        />
                                        : <img alt="Crop" id="cropImgId" className="previewImglass" src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + file ? process.env.REACT_APP_IMG_CLOUD_BASE_URL + file : defaultSrc} />
                                }
                            </figure>
                            <div className="dk-userChoosFile">
                                <input type="file" accept="image/*" name="jobseekerLogo" onChange={onSelectFile} />
                                <span>Change Photo</span>
                            </div>

                        </div>
                    </div>

                    <div className="dk-userEditForm">
                        <form action="">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Full Name </label>
                                        <label htmlFor="">
                                            <input type="text" value={userName} name="" id="" className="form-control" onChange={(e) => setUserName(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Role</label>
                                        <label htmlFor="">
                                            <input type="text" value={role} name="" id="" className="form-control" readOnly style={{ cursor: "not-allowed" }} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Current Country</label>
                                        <label htmlFor="">
                                            <input type="text" value={currentCountry} name="" id="" className="form-control" onChange={(e) => setCurrentCountry(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Current City</label>
                                        <label htmlFor="">
                                            <input type="text" value={currentCity} name="" id="" className="form-control" onChange={(e) => setCurrentCity(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Nationality - Optional</label>
                                        <label htmlFor="">
                                            <input type="text" value={nationality} name="" id="" className="form-control" onChange={(e) => setNationality(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Date of Birth</label>
                                        <label htmlFor="" className="dk-userDate">
                                            <input type="text" value={birthDate} name="" id="" className="form-control" onChange={(e) => setBirthDate(e.target.value)} />
                                            <span><i class="far fa-calendar-alt"></i></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Primary Email Address</label>
                                        <label htmlFor="">
                                            <input type="text" value={email} name="" id="" className="form-control" readOnly style={{ cursor: "not-allowed" }} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Backup Email Address</label>
                                        <label htmlFor="">
                                            <input type="text" value={backupEmail} name="" id="" className="form-control" onChange={(e) => setBackupEmail(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Mobile No</label>
                                        <label htmlFor="">
                                            <input type="text" value={mobileNo} name="" id="" className="form-control" onChange={(e) => setMobileNo(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Landline No.</label>
                                        <label htmlFor="">
                                            <input type="text" value={landlineNo} name="" id="" className="form-control" onChange={(e) => setLandlineNo(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Skype ID - Optional</label>
                                        <label htmlFor="">
                                            <input type="text" value={skypeId} name="" id="" className="form-control" onChange={(e) => setSkypeId(e.target.value)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">WhatsApp - Optional</label>
                                        <label htmlFor="">
                                            <input type="text" value={whatsApp} name="" id="" className="form-control" onChange={(e) => setWhatsapp(e.target.value)} />
                                        </label>
                                    </div>
                                </div>

                                <div className="dk-userSbtitle">Reset Password</div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Password</label>
                                        <label htmlFor="" className="dk-userPassword">

                                            <input type={showPassword ? "text" : "password"} className="form-control" name="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="*****" />

                                            <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </span>

                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="">Confirm Password</label>
                                        <label htmlFor="" className="dk-userPassword">
                                            <input type={showConfirmPassword ? "text" : "password"} className="form-control" name="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} placeholder="*****" />

                                            <span className="dk-eyeICon domainAppendSpan" onClick={handleClickShowConfirmPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                            </span>

                                        </label>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                <div className="dk-userEditBtn">
                    <SubmitButton txt="Save Profile" loading={loading} success={success} onClick={submitUserDetails} className="dk-userAddBtn" position="justify-content-center" />
                    {/* <button className="dk-userSaveprofileBtn">Save Profile</button> */}
                </div>
            </div>
        </>
    )
}
export default UpdateUserDetails;