import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, Redirect, Route } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import styles from './Login.module.css';
import Logo from '../../../assets/images/admin_logo.png';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import { showError, clearMessage } from "../../../slice/utils/message/messageSlice"
import { adminlogin, clearState } from "../../../slice/auth/authSlice";
import './login.scss';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useCookies } from 'react-cookie';
const nodeBase64 = require('nodejs-base64-converter');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: 40,
        marginBottom: 40,
    },
    margin: {
        margin: theme.spacing(1),
    },
    activeLoginBtn: {
        backgroundColor: '#10333c'
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(20),
        width: '25ch'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -12,
    },
    loginButtonsTabAdmin: {
        backgroundColor: '#2b7a8a !important',
        color: '#fff !important',
        borderRadius: '0',
        border: 'none'
    },
    activeLoginBtn: {
        backgroundColor: '#fff'
    }
}));

const Login = () => {
    const classes = useStyles();
    const [activeUser, setActiveUser] = useState('activeLoginBtn');
    const [activeAdmin, setActiveAdmin] = useState('');

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [cookies, setCookie] = useCookies(['user']);

    const [role, setRole] = useState('Admin');
    const [email, setEmail] = useState(cookies.UserEmail);
    const [password, setPassword] = useState(cookies.UserPwd);
    const [restFlag, setRestFlag] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const history = useHistory();
    const { isSuccess, isError, isLoading, errorMessage, isAuthenticate } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const clickSetRole = (roleType) => {
        setRole(roleType)
        if (roleType == 'Admin') {
            setActiveUser('activeLoginBtn')
            setActiveAdmin('')
        } else {
            setActiveUser('')
            setActiveAdmin('activeLoginBtn')
        }
        // if (roleType == 'User') {
        //     window.location.href = "https://localhost:3000";
        // }
    }

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;
            case "password":
                setPassword(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!email || !password) {
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            try {
                dispatch(clearMessage())
                setCookie('UserEmail', email, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });
                setCookie('UserPwd', password, { path: '/', expires: moment().add(30, "days").toDate(), sameSite: true });

                await dispatch(adminlogin({ role, email, password }))

                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                } else {
                    setSuccess(true);
                    setLoading(false);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        document.title = "Login | DK Masterbox"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState());
        }

    }, [isError, isSuccess]);


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const forgotPasswordFun = () => {
        setRestFlag(true)
    }

    return (
        <React.Fragment>
            <Route exact path="/">
                {restFlag ? <Redirect to={"/forget-password/" + nodeBase64.encode(role)} /> : ""}
            </Route>

            <div className="dk-adminLoginMain">
                <div className="dk-adminLoginCols">
                    <div className="dk-loginBoxContainer">
                        <div className="dk-adminLogo">
                            <img src={Logo} className="img-fluid" alt="" />
                        </div>
                        <Tabs defaultActiveKey="Admin" onSelect={clickSetRole} id="uncontrolled-tab-example">

                            <Tab eventKey="Admin" title="Admin">
                                <TextField
                                    name="email"
                                    className={classes.margin + classes.textField}
                                    id="input-with-icon-textfield"
                                    label=""
                                    placeholder="Username"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon style={{ color: 'white' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleChange}
                                    value={email}
                                />
                                <div className="dk-adminPassText">
                                    <TextField
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        className={classes.margin + classes.textField}
                                        id="input-with-icon-textfield"
                                        label=""
                                        placeholder="Password"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon style={{ color: 'white' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={handleChange}
                                        value={password}
                                    />
                                    <span className="dk-passHideShow" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </span>
                                </div>


                                <div className={classes.wrapper + " col-12 mt-0 ml-0 mr-0 mb-3 d-flex justify-content-center"}>
                                    <Button variant="contained" className={styles.loginButton + " mt-3 " + buttonClassname} disabled={loading} onClick={handleButtonClick}><Typography className="dklogin-titleColor">Login</Typography></Button>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Tab>
                            <Tab eventKey="Super Admin" title="Super Admin" >
                                <TextField
                                    name="email"
                                    className={classes.margin + classes.textField}
                                    id="input-with-icon-textfield"
                                    label=""
                                    placeholder="Username"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon style={{ color: 'white' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={handleChange}
                                    value={email}
                                />
                                <div className="dk-adminPassText">
                                    <TextField
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        className={classes.margin + classes.textField}
                                        id="input-with-icon-textfield"
                                        label=""
                                        placeholder="Password"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon style={{ color: 'white' }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={handleChange}
                                        value={password}
                                    />
                                    <span className="dk-passHideShow" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </span>
                                </div>


                                <div className={classes.wrapper + " col-12 mt-0 ml-0 mr-0 mb-3 d-flex justify-content-center"}>
                                    <Button variant="contained" className={styles.loginButton + " mt-3 " + buttonClassname} disabled={loading} onClick={handleButtonClick}><Typography className="dklogin-titleColor">Login</Typography></Button>
                                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                            </Tab>
                            {/* <Tab eventKey="User" title="User"></Tab> */}
                        </Tabs>

                        <div className="col-12 d-flex justify-content-center">
                            <Link to="#" onClick={forgotPasswordFun} className="loginForgot"><Typography>Forget Password</Typography></Link>
                            {/* /forget-password */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Login;