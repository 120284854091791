import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import styles from './forgetPassword.module.css';
import Logo from '../../../assets/images/admin_logo.png';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice"
import { changePassword, clearState } from "../../../slice/auth/authSlice";
import '../login/login.scss';
const nodeBase64 = require('nodejs-base64-converter');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: 40,
        marginBottom: 40,
    },
    margin: {
        margin: theme.spacing(1),
    },
    activeLoginBtn: {
        backgroundColor: '#10333c'
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(20),
        width: '25ch'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -12,
    },
    // loginButtonsTabUser: {
    //     backgroundColor: '#10333c !important',
    //     border: 'none',
    //     borderRadius: '0'
    // },
    loginButtonsTabAdmin: {
        backgroundColor: '#2b7a8a !important',
        color: '#fff !important',
        borderRadius: '0',
        border: 'none'
    },
    activeLoginBtn: {
        backgroundColor: '#fff'
    }
}));

const ForgetPassword = () => {
    const url = window.location.href;
    const roleType = url.substring(url.lastIndexOf('/') + 1);
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const [email, setEmail] = useState('');
    const { resendEmail, isError, isLoading, errorMessage } = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "email":
                setEmail(value)
                break;
            default:
                break;
        }
    }

    const handleButtonClick = async () => {
        if (!email) {
            dispatch(showError({ msg: "Rest link has been send" }))
        } else {
            try {
                dispatch(clearMessage())
                await dispatch(changePassword({ email, roleType }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                } else {
                    setSuccess(false);
                    setLoading(false);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        document.title = "Forgot Password | DK Masterbox"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState());
        }
        if (resendEmail) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: "Rest link has been send" }))
            setEmail('')
            dispatch(clearState())
        }
    }, [isError, resendEmail]);

    return (
        <React.Fragment>
            <div className="dk-adminLoginMain">
                <div className="dk-adminLoginCols">
                    <div className="dk-loginBoxContainer">
                        <a href={"/"}>
                            <div className="dk-adminLogo">
                                <img src={Logo} className="img-fluid" alt="" />
                            </div>
                        </a>
                        <TextField
                            name="email"
                            className={classes.margin + classes.textField}
                            id="input-with-icon-textfield"
                            label=""
                            placeholder="Email id"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon style={{ color: 'white' }} />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handleChange}
                            value={email}
                        />

                        <div className={classes.wrapper + " col-12 mt-0 ml-0 mr-0 mb-3 d-flex justify-content-center"}>
                            <Button variant="contained" className={styles.loginButton + " mt-3 " + buttonClassname} disabled={loading} onClick={handleButtonClick}><Typography className="dklogin-titleColor">Submit</Typography></Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default ForgetPassword;