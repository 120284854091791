import React from 'react';
import { Route, Link } from "react-router-dom";
import '../submenu/submenu.scss';

const DashboardSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/dashboard/summary" className={`${pathname.match('/dashboard/summary') ? 'sub-active' : ''}`}>Summary</Link></li>

                        <li><Link to="/dashboard/mailbox" className={`${pathname.match('/dashboard/mailbox') ? 'sub-active' : ''}`}>Mailbox</Link></li>

                        <li><Link to="/dashboard/tasks" className={`${pathname.match('/dashboard/tasks') ? 'sub-active' : ''}`}>Tasks</Link></li>

                        <li><Link to="/dashboard/actions" className={`${pathname.match('/dashboard/actions') ? 'sub-active' : ''}`}>Actions</Link></li>

                        <li><Link to="/dashboard/notification" className={`${pathname.match('/dashboard/notification') ? 'sub-active' : ''}`}>Notification</Link></li>

                        <li><Link to="/dashboard/workplan" className={`${pathname.match('/dashboard/workplan') ? 'sub-active' : ''}`}>Workplan</Link></li>

                        <li><Link to="/dashboard/closure" className={`${pathname.match('/dashboard/closure') ? 'sub-active' : ''}`}>Closure</Link></li>

                        <li><Link to="/dashboard/dk-global" className={`${pathname.match('/dashboard/dk-global') ? 'sub-active' : ''}`}>DK Global</Link></li>

                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default DashboardSubmenu;
