import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from 'array-move';
import ColorPickerModal from "./ColorPickerModal";
import { useDispatch, useSelector } from "react-redux";
import { valuesList, reorderValues, updateValue, deleteBkValue, clearState } from "../../../../slice/backendValues/backendValuesSlice";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import DeleteModal from "../../../ui/delete";
import $ from "jquery";
import ValueInput from "./ValueInput";


export function ValueList({ cateId }) {
    const dispatch = useDispatch();
    const [list, setList] = useState([])
    const { isDelete, isError, msg, isloading, backendValuesList } = useSelector(state => state.backendValues)
    const [showDelete, setShowDelete] = useState(false)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [rowId, setRowId] = useState('0')

    useEffect(() => {
        dispatch(valuesList({ categoryId: cateId }))
    }, [cateId])

    const handleCloseDelete = () => {
        setShowDelete(false)
        setRowId('0')
    }
    const deleteValue = async () => {
        setSuccess(false);
        setLoading(true);
        await dispatch(deleteBkValue({ id: rowId }))
    }

    const onRemove = (index) => {
        setShowDelete(true)
        setRowId(index)
    }
    useEffect(() => {
        if (!isloading) {
            setList(backendValuesList)
        }
    }, [isloading, backendValuesList])

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg }))
            setShowDelete(false)
            setSuccess(false);
            setLoading(false);
            setRowId('0');
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
        }
    }, [isDelete, isError])

    const updateTypeValue = async (e) => {
        await dispatch(updateValue({ id: e.target.id, value: e.target.value }))
    }
    return (
        <>
            {
                (showDelete) ? <DeleteModal id={rowId} handleClose={handleCloseDelete} deleteItem={deleteValue} /> : null
            }
            {list.map((value, index) =>
                <div className="Mukul">
                    <div className="dk-valueFields d-flex" key={value.id} id={value.id}>
                        <ValueInput id={value.id} background={value.bgColor} color={value.textColor} value={value.value} onBlur={updateTypeValue} />
                        {/* <input type="text" id={value.id} style={{ background: value.bgColor, color: value.textColor, height: "35px", borderRadius: "0", border: "1px solid #ddd", boxShadow: "none !important" }} value={value.value} onChange={updateTypeValue} onBlur={updateTypeValue} className="form-control" /> */}
                        <span className="dk-toolsValueDelete" onClick={() => onRemove(value.id)} style={{ backgroundColor: "#dc3545", height: "35px", width: "50px", textAlign: "center", lineHeight: "35px", color: "white", cursor: "pointer" }}><i class="fas fa-times"></i></span>
                        <ColorPickerModal id={value.id} backgroundColor={value.bgColor} textColor={value.textColor} value={value.value} categoryId={value.categoryId} />
                    </div>
                </div>
            )}

        </>
    )
}



export default ValueList;
