import React from 'react';
import "./App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Switch, Route } from "react-router-dom";
import LoginRoute from "./components/hocs/LoginRoute";
import PrivateRoute from "./components/hocs/PrivateRoute";
import CheckLogin from "./components/hocs/checkLogin";
//Start Dashboard Page
import DashboardSuMM from './components/pages/dashboard/Dashboard1';
import DashboardMailbox from './components/pages/dashboard/Mailbox';
import DashboardTasks from './components/pages/dashboard/Tasks';
import DashboardActions from './components/pages/dashboard/Actions';
import DashboardNotification from './components/pages/dashboard/Notification';
import DashboardWorkplan from './components/pages/dashboard/Workplan';
import DashboardClosure from './components/pages/dashboard/Closure';
import DashboardDkGlobal from './components/pages/dashboard/DkGlobal';
// End Dashboard Page
// Admin Start
import BkValues from "./components/pages/tools/bkvalues/BkValues";
import UsersManagement from "./components/pages/settings/usermanagement/UsersManagement";
import AdminSummary from "./components/pages/training/summary/AdminSummary";
import AdminOnboarding from "./components/pages/training/onboarding/AdminOnboarding";
import AdminGetStarted from "./components/pages/training/getstarted/AdminGetStarted";
import AdminTools from "./components/pages/training/tools/AdminTools";
import AdminMasterbox from "./components/pages/training/masterbox/AdminMasterbox";
import AdminCommon from "./components/pages/training/common/AdminCommon";
import Admin from "./components/pages/training/admins/Admins";
import AdminRecruiters from "./components/pages/training/recruiters/AdminRecruiters";
import AdminManagers from "./components/pages/training/managers/AdminManagers";
import AccountSummary from "./components/pages/accounts/AccountSummary";
import AccountProfitLoss from "./components/pages/accounts/AccountProfitLoss";
import AccountIncomes from "./components/pages/accounts/AccountIncomes";
import AccountExpenses from "./components/pages/accounts/AccountExpenses";
import AccountCashflows from "./components/pages/accounts/AccountCashflows";
// End
import forgetPassword from "./components/pages/forgetPassword";
import resetPassword from "./components/pages/forgetPassword/resetPassword";

import adminLogin from './components/pages/login';

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <Switch>
          <LoginRoute exact path="/" component={adminLogin} />
          {/* Start Dashboard Page */}
          <PrivateRoute path="/dashboard/summary" component={DashboardSuMM} />
          <PrivateRoute path="/dashboard/mailbox" component={DashboardMailbox} />
          <PrivateRoute path="/dashboard/tasks" component={DashboardTasks} />
          <PrivateRoute path="/dashboard/actions" component={DashboardActions} />
          <PrivateRoute path="/dashboard/notification" component={DashboardNotification} />
          <PrivateRoute path="/dashboard/workplan" component={DashboardWorkplan} />
          <PrivateRoute path="/dashboard/closure" component={DashboardClosure} />
          <PrivateRoute path="/dashboard/dk-global" component={DashboardDkGlobal} />
          {/* End Dashboard Page */}
          {/* Admin Pages Start */}
          <PrivateRoute path="/admin/tools/bkvalues" component={BkValues} />
          <PrivateRoute path="/admin/settings/users" component={UsersManagement} />
          <PrivateRoute path="/admin/training/summary" component={AdminSummary} />
          <PrivateRoute path="/admin/training/onboarding" component={AdminOnboarding} />
          <PrivateRoute path="/admin/training/get-started" component={AdminGetStarted} />
          <PrivateRoute path="/admin/training/training-tools" component={AdminTools} />
          <PrivateRoute path="/admin/training/masterbox" component={AdminMasterbox} />
          <PrivateRoute path="/admin/training/common" component={AdminCommon} />
          <PrivateRoute path="/admin/training/admins" component={Admin} />
          <PrivateRoute path="/admin/training/recruiters" component={AdminRecruiters} />
          <PrivateRoute path="/admin/training/managers" component={AdminManagers} />
          <PrivateRoute path="/admin/accounts/summary" component={AccountSummary} />
          <PrivateRoute path="/admin/accounts/profit-loss" component={AccountProfitLoss} />
          <PrivateRoute path="/admin/accounts/incomes" component={AccountIncomes} />
          <PrivateRoute path="/admin/accounts/expenses" component={AccountExpenses} />
          <PrivateRoute path="/admin/accounts/cashflows" component={AccountCashflows} />
          <LoginRoute exact path="/admin/login" component={adminLogin} />
          {/* Admin Pages End */}
          <Route path="/forget-password/:role" component={forgetPassword} />
          <Route path="/reset-password/:roleType/:email/:accessToken" component={resetPassword} />
          <Route path="/check-auth/:slug" component={CheckLogin} />
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
