import React from 'react';
import { Route, Link } from "react-router-dom";
import '../submenu/submenu.scss';

const TrainingSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/admin/training/summary" className={`${pathname.match('/admin/training/summary') ? 'sub-active' : ''}`}>Summary</Link></li>

                        <li><Link to="/admin/training/onboarding" className={`${pathname.match('/admin/training/onboarding') ? 'sub-active' : ''}`}>Onboarding</Link></li>
                        
                        <li><Link to="/admin/training/get-started" className={`${pathname.match('/admin/training/get-started') ? 'sub-active' : ''}`}>Get Started</Link></li>
                        
                        <li><Link to="/admin/training/training-tools" className={`${pathname.match('/admin/training/training-tools') ? 'sub-active' : ''}`}>Tools</Link></li>

                        <li><Link to="/admin/training/masterbox" className={`${pathname.match('/admin/training/masterbox') ? 'sub-active' : ''}`}>Masterbox</Link></li>

                        <li><Link to="/admin/training/common" className={`${pathname.match('/admin/training/common') ? 'sub-active' : ''}`}>Common</Link></li>

                        <li><Link to="/admin/training/Admins" className={`${pathname.match('/admin/training/Admins') ? 'sub-active' : ''}`}>Admins</Link></li>

                        <li><Link to="/admin/training/recruiters" className={`${pathname.match('/admin/training/recruiters') ? 'sub-active' : ''}`}>Recruiters</Link></li>

                        <li><Link to="/admin/training/managers" className={`${pathname.match('/admin/training/managers') ? 'sub-active' : ''}`}>Managers</Link></li>

                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default TrainingSubmenu;
