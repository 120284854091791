import React, { useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import './navbar.scss';

const AdminNavbar = () => {
    const pathname = window.location.pathname;
    useEffect(() => {
        document.title = pathname.split('/')['1'].charAt(0).toUpperCase() + pathname.split('/')['1'].slice(1) + " | " + "DK MasterBox";
    }, []);

    const { allBadge } = useSelector(state => state.tasks);
    return (
        <React.Fragment>
            <div className="dk-navBarwrapper">
                <div className="dk-navBarMain dk-adminNavbar">
                    <Route>
                        <ul>
                            <li>
                                <Link to="/dashboard/summary" className={`${pathname.match('/dashboard') ? 'active' : ''}`}>Dashboard </Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Company</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Jobs</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Specs</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Candidates</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Ads</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/admin/accounts/summary" className={`${pathname.match('/accounts') ? 'active' : ''}`}>Accounts</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/admin/training/summary" className={`${pathname.match('/training') ? 'active' : ''}`}>Training</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="#" className={`${pathname.match('/') ? 'sub-active' : ''}`}>Knowledge</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/admin/tools/bkvalues" className={`${pathname.match('/tools') ? 'active' : ''}`}>Tools</Link>
                            </li>
                            <li className="dropdown-toggle">
                                <Link to="/admin/settings/users" className={`${pathname.match('/settings') ? 'active' : ''}`}>Settings</Link>
                            </li>
                        </ul>
                    </Route>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminNavbar;
