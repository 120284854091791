import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import ProgressBarReducer from '../slice/utils/loader/linearSlice';
import alertMessagesReducer from '../slice/utils/message/messageSlice';
import authReducer from '../slice/auth/authSlice';
import tokenReducer from '../slice/auth/tokenSlice';
import userListReducer from '../slice/userList/userSlice';
import backendValuesReducer from '../slice/backendValues/backendValuesSlice';
import adminUserListReducer from '../slice/admin/user/userSlice';
import tasksReducer from '../slice/tasks/taskSlice';
const reducers = combineReducers({
  progressBar: ProgressBarReducer,
  alertMessage: alertMessagesReducer,
  auth: authReducer,
  tasks: tasksReducer,
  token: tokenReducer,
  users: userListReducer,
  backendValues: backendValuesReducer,
  alluserList: adminUserListReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'module', 'users']
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});