import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
const NoData = ({ colspan, txt }) => {
    return (
        <React.Fragment>
            <tbody>
                <tr>
                    <td colspan={colspan} style={{ padding: '5px .75rem', color: 'red' }}>{txt}</td>
                </tr>
            </tbody>
        </React.Fragment>
    );
}
export default NoData;