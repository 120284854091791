import React, { useState } from 'react';
import AdminHeader from "../../elements/header/Header";
import AdminNavbar from "../../elements/navbar/Navbar";
import DashboardSubmenu from '../submenu/DashboardSubmenu';
import './adminaccounts.scss';

const Actions = () => {
    return (
        <>
            <AdminHeader />
            <AdminNavbar />
            <DashboardSubmenu />
        </>
    )
}

export default Actions;

