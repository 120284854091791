import React from 'react';
import { Route, Link } from "react-router-dom";
import '../submenu/submenu.scss';

const AccountSubmenu = () => {
    const pathname = window.location.pathname;
    return (
        <div>
            <div className="dk-adminSubmenuMain">
                <Route>
                    <ul>
                        <li><Link to="/admin/accounts/summary" className={`${pathname.match('/admin/accounts/summary') ? 'sub-active' : ''}`}>Summary</Link></li>

                        <li><Link to="/admin/accounts/profit-loss" className={`${pathname.match('/admin/accounts/profit-loss') ? 'sub-active' : ''}`}>Profit/Loss</Link></li>

                        <li><Link to="/admin/accounts/incomes" className={`${pathname.match('/admin/accounts/incomes') ? 'sub-active' : ''}`}>Incomes</Link></li>

                        <li><Link to="/admin/accounts/expenses" className={`${pathname.match('/admin/accounts/expenses') ? 'sub-active' : ''}`}>Expenses</Link></li>

                        <li><Link to="/admin/accounts/cashflows" className={`${pathname.match('/admin/accounts/cashflows') ? 'sub-active' : ''}`}>Cashflows</Link></li>

                    </ul>
                </Route>
            </div>
        </div>
    )
}

export default AccountSubmenu;
