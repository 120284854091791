import React from 'react';
import { Link, Route } from "react-router-dom";
import AdminLogo from "../../../assets/images/admin_logo.png";
import './header.scss';
import Dropdown from 'react-bootstrap/Dropdown'
import { logoutUser } from "../../../slice/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
const AdminHeader = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);
    const logout = async () => {
        dispatch(logoutUser({}))
    }
    const pathname = window.location.pathname;
    return (
        <React.Fragment>
            <div className="dk-adminMainHeader">
                <div className="dk-adminNavbar">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="dk-adminLogo">
                            <Link to="#">
                                <img src={AdminLogo} className="img-fluid" alt="" />
                            </Link>
                        </div>
                        <div className="dk-nav-title">
                            <span>ADMIN PANEL - DK GLOBAL CRM</span>
                        </div>
                        <div className="dk-nav-profile">
                            <Dropdown>
                                <div className="dropdown-img">
                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} title={userInfo.firstName} className="img-fluid" alt="" />
                                </div>
                                <span className="dk-userIcon">Deepak</span>
                                <Dropdown.Toggle variant="" id="dropdown-basic">

                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" onClick={logout}><i class="fas fa-sign-out-alt mr-2"></i> Sign Out</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default AdminHeader;
