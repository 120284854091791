import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const checkToken = createAsyncThunk(
    'token/check',
    async ({ token }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/user/verify',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': token
                    },
                }
            );
            let data = await response.json();
            if (response.status === 304 || response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const genrateNewToken = createAsyncThunk(
    'token/refresh',
    async ({ refreshToken }, thunkAPI) => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/user/token',
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-auth-token': refreshToken
                    },
                }
            );
            let data = await response.json();
            if (response.status === 200) {
                localStorage.setItem(
                    "dkmasterBoxAdmin",
                    JSON.stringify({
                        token: data.token,
                        refreshToken: data.refreshToken
                    })
                );
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

const tokenSlice = createSlice({
    name: 'toekn',
    initialState: {
        isVerified: true,
        isRefreshed: true
    },
    reducers: {
        clearState: (state) => {
            state.isVerified = true;
            state.isRefreshed = true;
        }
    },
    extraReducers: {
        [checkToken.pending]: (state) => {
        },
        [checkToken.fulfilled]: (state, { payload }) => {
            state.isVerified = true
        },
        [checkToken.rejected]: (state, { payload }) => {
            state.isVerified = false;
        },
        [genrateNewToken.pending]: (state) => {
        },
        [genrateNewToken.fulfilled]: (state, { payload }) => {
            state.isVerified = true;
            state.isRefreshed = true;
        },
        [genrateNewToken.rejected]: (state, { payload }) => {
            state.isVerified = false;
            state.isRefreshed = false;
        },
    }
});
export const { clearState } = tokenSlice.actions;
export default tokenSlice.reducer;