import React, { useState, useEffect } from "react";
import { registerUser, clearState } from "../../../../slice/admin/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../components/utils/messages/messageSlice";
import SubmitButton from "../../../../components/ui/submitButton";
import Constants from "../../../../constants";

const AddModal = () => {
    const { msg, isError, isInsert } = useSelector(state => state.alluserList);
    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('-1');
    const [status, setStatus] = useState('-1');

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const addNewUser = (e) => {
        e.preventDefault()
        setSuccess(false);
        setLoading(true);
        const role = userType;
        if (!firstName || !lastName || !email || role == '-1' || status == '-1') {
            dispatch(showError({ msg: 'All fields required' }))
            setLoading(false);
        } else {
            setLoading(true);
            dispatch(registerUser({ firstName, lastName, email, password: 'dkglobal@2022', role, status }))

        }
    }
    useEffect(() => {
        if (isError) {
            dispatch(clearState())
            dispatch(showError({ msg: msg }))
            setLoading(false);
            setSuccess(false);
        }
        if (isInsert) {
            dispatch(clearState())
            dispatch(showSuccess({ msg: msg }))
            setFirstName('');
            setLastName('');
            setEmail('');
            setUserType('-1');
            setStatus('-1');
            setLoading(false);
            setSuccess(false);
        }
    }, [isError, isInsert])

    return (
        <>
            <div className="title">Add New User </div>
            <form>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">First Name</label>
                            <label htmlFor="">
                                <input type="text" className="form-control" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">Last Name</label>
                            <label htmlFor="">
                                <input type="text" className="form-control" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                            </label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="">Email Address</label>
                            <label htmlFor="">
                                <input type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} />
                            </label>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">User Type</label>
                            <label htmlFor="">
                                <select className="form-control" onChange={(e) => setUserType(e.target.value)} value={userType}>
                                    <option value="-1">Select Type</option>
                                    <option value="Super Admin">Super Admin</option>
                                    <option value="Admin">Admin</option>
                                    <option value="User">User</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="">Account Status</label>
                            <label htmlFor="">
                                <select className="form-control" onChange={(e) => setStatus(e.target.value)} value={status}>
                                    <option value="-1">Select Status</option>
                                    <option value="1">Active</option>
                                    <option value="-1">Inactive</option>
                                </select>
                            </label>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group mb-1 mt-2 text-center">
                            <div className="justify-content-center">
                                <div className="dk-createBtn">
                                    <SubmitButton txt="CREATE" loading={loading} success={success} onClick={addNewUser} position="justify-content-center" className="dk-userAddBtn" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default AddModal;