import React, { useState, useEffect } from 'react';
import { Route, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './bkvalues.scss';
import './maincategory.scss'
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../../slice/utils/message/messageSlice";
import { categoryDelete, clearState } from "../../../../slice/backendValues/backendValuesSlice";
import DeleteModal from "../../../../../src/components/ui/delete";
import { List } from "./List";
import { ReorderCategory } from "./ReorderCategory";

const EditCategoryModal = ({ boxNo, parentId1, parentId2, parentId3, parentId4, parentId5, headingText }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { box1List, box2List, box3List, box4List, box5List, isReoder, isError, isDelete, msg } = useSelector(state => state.backendValues)

    const [data, setData] = useState([])

    const [rowId, setRowId] = useState('0')
    const [showDelete, setShowDelete] = useState(false)

    useEffect(() => {
        switch (boxNo) {
            case '1':
                setData(box1List)
                break;

            case '2':
                setData(box2List)
                break;

            case '3':
                setData(box3List)
                break;

            case '4':
                setData(box4List)
                break;

            case '5':
                setData(box5List)
                break;
        }
    }, [boxNo, box1List, box2List, box3List, box4List, box5List])

    const deleteItem = (id) => {
        setShowDelete(true)
        setRowId(id)
    }

    const handleCloseDelete = () => {
        setShowDelete(false)
        setRowId('0')
    }

    const deleteValue = async () => {
        setSuccess(false);
        setLoading(true);
        await dispatch(categoryDelete({ boxNo, id: rowId }))
    }

    useEffect(() => {
        if (isDelete) {
            dispatch(clearState())
            dispatch(showSuccess({ msg }))
            setShowDelete(false)
            setRowId('0')
            setSuccess(false);
            setLoading(false);
        }
        if (isReoder) {
            dispatch(showSuccess({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
            switch (boxNo) {
                case '1':

                    break;
            }
        }
        if (isError) {
            dispatch(showError({ msg }))
            dispatch(clearState())
            setLoading(false)
            setSuccess(false)
        }
    }, [isDelete, isReoder, isError])
    return (
        <div>
            {
                (showDelete) ? <DeleteModal id={rowId} loading={loading} success={success} handleClose={handleCloseDelete} deleteItem={deleteValue} /> : null
            }
            <Link to="#" onClick={handleShow}><i class="far fa-edit"></i></Link>

            <Modal className="dk-editCategoryMain dkg-maincatgoryModal-11" show={show} onHide={handleClose}>
                <Modal.Header closeButton className='dkg-maincatgoryModalHeader-11'>
                    <Modal.Title>Main Category</Modal.Title>
                </Modal.Header>
                <Modal.Body className='dkg-maincatgoryModalBody-11'>
                    <div className="dk-toolsModalbody ">
                        <Tabs defaultActiveKey="edit" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="edit" title="Edit">
                                <div className="dk-toolsValuesPanel">
                                    {
                                        data.map((item, index) => (
                                            <List boxNo={boxNo} key={index + 1} number={index + 1} itemValue={item.name} id={item.id} parentId={item.parentId} />
                                        ))
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="reorder" title="Reorder">
                                <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                                    {
                                        <ReorderCategory dataList={data} boxNo={boxNo} parentId1={parentId1} parentId2={parentId2} parentId3={parentId3} parentId4={parentId4} parentId5={parentId5} />

                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="delete" title="Delete">
                                <div className="dk-toolsValuesPanel dk-toolsReorderPanel">
                                    {
                                        data.map((item, index) => (
                                            <div className="d-flex align-items-center" key={index + 1}>
                                                <input type="text" value={item.name} className="form-control" />
                                                <div className="dk-toolsPanel-updateIcon">
                                                    <i class="far fa-trash-alt" onClick={() => deleteItem(item.id)}></i>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditCategoryModal;
