import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { doLogin } from "../../../slice/auth/authSlice";
import { useDispatch } from "react-redux";

const CheckLogin = (props) => {
    const dispatch = useDispatch();
    const [isRedirect, setIsRedirect] = useState(false);

    useEffect(() => {
        const { slug } = props.match.params;
        async function checkLogin() {
            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL + '/admin/auth/check-auth',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.REACT_APP_WEB_API_KEY,
                            'platform': 'web',
                        },
                        body: JSON.stringify({
                            token: slug
                        }),
                    }
                );
                let data = await response.json();
                if (response.status === 200) {
                    localStorage.setItem(
                        "dkmasterBoxAdmin",
                        JSON.stringify({
                            token: data.result.token.access_token,
                            refreshToken: data.result.token.refresh_token
                        })
                    );
                    dispatch(doLogin(data.result))
                    setIsRedirect(true)
                } else {
                    window.location.assign(process.env.REACT_APP_REDIRECT_LOGOUT_URL);
                }
            } catch (err) {
                console.log(err)
            }
        }
        checkLogin()
    }, [])

    return (
        (isRedirect) ? <Redirect to="/admin/tools/bkvalues" /> : null
    )
}
export default CheckLogin;