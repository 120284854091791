import React, { useEffect, useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import styles from './forgetPassword.module.css';
import Logo from '../../../assets/images/admin_logo.png';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import { showSuccess, showError, clearMessage } from "../../../slice/utils/message/messageSlice"
import { updateUserPassword, changePassword, clearState } from "../../../slice/auth/authSlice";
import '../login/login.scss';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
        marginTop: 40,
        marginBottom: 40,
    },
    margin: {
        margin: theme.spacing(1),
    },
    activeLoginBtn: {
        backgroundColor: '#10333c'
    },
    textField: {
        margin: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(20),
        width: '25ch'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -12,
    },
    // loginButtonsTabUser: {
    //     backgroundColor: '#10333c !important',
    //     border: 'none',
    //     borderRadius: '0'
    // },
    loginButtonsTabAdmin: {
        backgroundColor: '#2b7a8a !important',
        color: '#fff !important',
        borderRadius: '0',
        border: 'none'
    },
    activeLoginBtn: {
        backgroundColor: '#fff'
    }
}));

const ResetPassword = (props) => {
    const nodeBase64 = require('nodejs-base64-converter');
    const { email, accessToken, roleType } = props.match.params;
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const history = useHistory();
    const { isSuccess, isError, isLoading, errorMessage, resendEmail } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    const handleChange = e => {
        const { name, value } = e.target
        switch (name) {
            case "password":
                setPassword(value)
                break;
            case "confirmPassword":
                setConfirmPassword(value)
                break;
            default:
                break;
        }
    }

    const resendPasswordLink = async () => {
        try {
            dispatch(clearMessage())
            await dispatch(changePassword({ 'email': nodeBase64.decode(email), 'roleType': roleType }))
            if (isLoading) {
                setSuccess(false);
                setLoading(true);
            }
        } catch (err) {
            setSuccess(false);
            setLoading(false);
        }

    };

    const handleButtonClick = async () => {

        if (!confirmPassword || !password) {
            dispatch(showError({ msg: "Please fill all required details" }))
        } else {
            try {
                dispatch(clearMessage())
                await dispatch(updateUserPassword({ roleType: roleType, email: email, accessToken: accessToken, confirmPassword: confirmPassword, password: password }))
                if (isLoading) {
                    setSuccess(false);
                    setLoading(true);
                }
            } catch (err) {
                setSuccess(false);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        document.title = "Reset Password | DK Masterbox"
        dispatch(clearMessage());
        dispatch(clearState());
    }, [dispatch])

    useEffect(() => {
        if (isError) {
            setSuccess(false);
            setLoading(false);
            dispatch(showError({ msg: errorMessage }))
            dispatch(clearState());
        }
        if (isSuccess) {
            setSuccess(true);
            setLoading(false);
            dispatch(showSuccess({ msg: "Password changed successfully" }))
            setConfirmPassword('')
            setPassword('')
            setTimeout(() => history.push('/'), 1000)

        }
    }, [isError, isSuccess]);

    useEffect(() => {
        if (resendEmail) {
            setSuccess(true);
            setLoading(false);
            dispatch(clearMessage())
            dispatch(clearState())
            dispatch(showSuccess({ msg: "Reset password link has been send to email" }))
        }
    }, [resendEmail, dispatch]);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <React.Fragment>
            <div className="dk-adminLoginMain">
                <div className="dk-adminLoginCols">
                    <div className="dk-loginBoxContainer">
                        <a href={'/'}>
                            <div className="dk-adminLogo">
                                <img src={Logo} className="img-fluid" alt="" />
                            </div>
                        </a>
                        <div className="dk-adminPassText">
                            <TextField
                                type={showPassword ? "text" : "password"}
                                name="password"
                                className={classes.margin + classes.textField}
                                id="input-with-icon-textfield"
                                label=""
                                placeholder="Password"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={{ color: 'white' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                value={password}
                            />
                            <span className="dk-passHideShow" onClick={handleClickShowPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </span>
                        </div>

                        <div className="dk-adminPassText">
                            <TextField
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                className={classes.margin + classes.textField}
                                id="input-with-icon-textfield"
                                label=""
                                placeholder="Confirm Password"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={{ color: 'white' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleChange}
                                value={confirmPassword}
                            />

                            <span className="dk-passHideShow" onClick={handleClickShowConfirmPassword} style={{ backgroundColor: '#e1e1e4' }}>
                                {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </span>
                        </div>
                        <div className={classes.wrapper + " col-12 mt-0 ml-0 mr-0 mb-3 d-flex justify-content-center"}>
                            <Button variant="contained" className={styles.loginButton + " mt-3 " + buttonClassname} disabled={loading} onClick={handleButtonClick}><Typography className="dklogin-titleColor">Submit</Typography></Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <Link to="#" className="loginForgot" onClick={resendPasswordLink}>Resend Email</Link>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default ResetPassword;