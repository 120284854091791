import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter as Router } from "react-router-dom";
// import SuccessSnackbar from "./components/utils/messages/SuccessSnackbar";
// import ErrorSnackbar from "./components/utils/messages/ErrorSnackbar";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import Success from "./components/utils/messages/success";
import Error from "./components/utils/messages/error";
import ProgressBar from "./components/utils/loader/linear";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import 'semantic-ui-css/semantic.min.css'

let persistor = persistStore(store);
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#333',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ProgressBar />
          <Success />
          <Error />
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <Provider store={store}>
//     <Router basename={'/resources'}>
//       <h1>Welcome</h1>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById('root')
// );
